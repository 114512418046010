<template>
  <!-- <div class="container-fluid"> -->
  <app-carousel />
  <app-footer />
  <!-- </div> -->
</template>

<script>
import AppCarousel from "@/components/AppCarousel.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: "HomeView",
  components: { AppCarousel, AppFooter },
};
</script>
