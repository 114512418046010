<template>
  <section class="section-subscribe bg-light">
    <div class="container">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12 mb-4">
          <h1 class="heading">Get news &amp; updates</h1>
        </div>
        <div class="col-md-12">
          <form action="" method="post">
            <div class="row">
              <div class="col-md-4 mb-4">
                <input
                  type="text"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="Your name here"
                />
              </div>
              <div class="col-md-4 mb-4">
                <input
                  type="email"
                  name=""
                  id=""
                  class="form-control"
                  placeholder="Your email here"
                />
              </div>
              <div class="col-md-4 mb-4">
                <input
                  type="submit"
                  name=""
                  id=""
                  class="btn btn-secondary w-100"
                  value="Subscribe"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>

  <footer class="site-footer">
    <div class="container">
      <div class="row mb-5">
        <div class="col-md-3 mb-5">
          <h2>About us</h2>
          <p>
            We specialize in offering cutting-edge automation solutions tailored
            for various industries. Our expertise lies in providing
            comprehensive automation services alongside facilitating seamless
            trading of automation products. At our core, we're dedicated to
            empowering businesses with efficient and innovative automation
            technologies.
          </p>
        </div>
        <div class="col-md-3 mb-5">
          <h2>Contact &amp; Address</h2>
          <ul class="list-unstyled footer-link">
            <li class="d-flex">
              <span class="me-3">Address: </span
              ><span class="text-white"
                >LIG C 57, NAINITAL ROAD, behind YES BANK, Awas Vikas, Rudrapur,
                Jagatpura, Uttarakhand 263153</span
              >
            </li>
            <li class="d-flex">
              <span class="me-3">Telephone: </span
              ><span class="text-white">+91-6399033777, +91-7300733777</span>
            </li>
            <li class="d-flex">
              <span class="me-3">Email: </span
              ><span class="text-white">securetechrdr@gmail.com</span>
            </li>
          </ul>
        </div>
        <div class="col-md-3 mb-5">
          <h2>Quick Links</h2>
          <ul class="list-unstyled footer-link">
            <li><a href="#">About us</a></li>
            <li><a href="#">Blog Articles</a></li>
            <li><a href="#">Contact Us</a></li>
          </ul>
        </div>
        <div class="col-md-3 mb-5">
          <h2>Our social media</h2>
          <ul class="list-unstyled footer-link footer-social d-flex">
            <li>
              <a href="https://www.facebook.com/securetechrdr" target="_blank"
                ><span class="fa fa-facebook"></span
              ></a>
            </li>
            <!-- <li><a href="#"><span class="fa fa-twitter"></span></a></li> -->
            <li>
              <a href="https://www.instagram.com/secure_tech_01" target="_blank"
                ><span class="fa fa-instagram"></span
              ></a>
            </li>
            <li>
              <a href="#"><span class="fa fa-linkedin"></span></a>
            </li>
            <li>
              <a href="#"><span class="fa fa-youtube"></span></a>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text md center text-left">
          <p>&copy; 2024 Secure Tech. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.section-subscribe {
  padding: 5em 0;
}

.form-control {
  outline: none;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  height: 50px;
}

.form-control:focus {
  -webkit-box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.2) !important;
}

.form-control:focus,
.form-control:focus:active {
  outline: none;
}

.btn {
  -webkit-box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.2) !important;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.2) !important;
}

.btn.btn-secondary {
  color: #fff;
  border-width: 2px;
  background: #bc5148;
  border-color: #bc5148;
  padding: 10px;
}

.site-footer {
  padding: 5em 0;
  background: #000;
  font-size: 14px;
  color: #868e96;
}

.site-footer p:last-child {
  margin-bottom: 0;
}

.site-footer a {
  color: #fff;
  border-bottom: 1px solid transparent;
  text-decoration: none;
}

.site-footer a:hover {
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.site-footer h2 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 28px;
  letter-spacing: 0.05em;
}

.site-footer .footer-link li {
  line-height: 1.5;
  margin-bottom: 15px;
}

.footer-social a {
  width: 30px;
  height: 30px;
  line-height: 0;
  text-align: center;
  border-radius: 50%;
  margin: 0 5px 5px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: inline-block;
}
</style>
